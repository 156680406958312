import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../HomePage/Spinner";
import moment from "moment/moment";
import { Helmet } from "react-helmet";
import TopHeading from "../TopHeading";
import ReactPaginate from "react-paginate";
import "../../assets/css/default/pagination.css"

const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.title =
    "Techtextil 2023 – Atlanta, GA | McCoy Machinery Company, Inc.";

  const [isLoading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);

  // pagination cards 
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 6;

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/news"
        );
        setBlogs(data.blog);
        setCards(data.blog);
        setCtaSection(data.newscta);
        setLoading(false);
        console.log("cards", data.blog)
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
    // console.log("news", data);

  }, []);

  // Get Current Page Cards 
  const offset = currentPage * cardsPerPage;
  const currentCards = cards.slice(offset, offset + cardsPerPage)
  const pageCount = Math.ceil(cards.length / cardsPerPage)

  const handlePageClick = ({selected}) =>{
    setCurrentPage(selected)
  }

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>McCoy Textile News - Innovations & Updates</title>
        <meta name="description" Content="Stay updated with the latest McCoy news, including new product launches, industry trends, and company updates." />
        <meta name="keywords" content="McCoy Textile News" />
      </Helmet>
      
      <TopHeading
        title={"Latest News from McCoy"}
        name={"News & Events"}
      />

      <section className="py-5">
        <div className="container-box pt-md-4 ">
          <div className="mb-5">
            <h1 className="text-dark-blue h2">SHOWS & INDUSTRY NEWS</h1>
          </div>
          <div className="row">
            {currentCards.map((news, i) => {
              return (
                <div className="col-lg-6" key={i}>
                  <div className="news-card bg-light p-4 border">
                    <div className="news-image">
                      <Link to={`/event-details/${news.slug}`}>
                        <img
                          src={`https://mccoy-usa.com/admin/assets/front/img/blogs/${news.main_image}`}
                          alt=""
                          className="border"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="news-content">
                      <Link
                        to={`/event-details/${news.slug}`}
                        className="text-decoration-none text-dark-blue fw-bold"
                      >
                        <h5 className="fw-bold mb-3 text-dark-blue">
                          {news.title}
                        </h5>
                      </Link>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: news.content.slice(0, 150) + "...",
                          }}
                        />
                     
                      </p>

                      <div className="d-flex">
                        <Link
                          to={`/event-details/${news.slug}`}
                          className="text-decoration-none text-dark-blue fw-bold"
                        >
                          Published On : {moment(news.post_date ? news.post_date : news.created_at).format("DD")},{" "}
                          {moment(news.post_date ? news.post_date : news.created_at).format("MMMM")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* pagination */}
          <ReactPaginate 
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
          />

        </div>
      </section>

      <section className="py-5 section-2">
        <div className="container-box py-4 row align-items-center">
          {ctaSection.map((item, index) => {
            return (
              <div className="col-md-7 text-white" key={index}>
                <h2 className="h2">{item.newscta_title}</h2>
                <h5 className="h5 py-2">{item.newscta_subtitle}</h5>
                <p className="paragraph mt-3">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.newscta_overview,
                    }}
                  />
                </p>
                <Link
                  to="/contact/request-a-quotes"
                  className="carousel-content-btn d-inline-block mt-4"
                >
                  {" "}
                  {item.newscta_btntext}
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default News;
