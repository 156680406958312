import React, { Suspense, useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// css
import "../../assets/css/Home.css";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";
import Spinner from "./Spinner";
import moment from "moment/moment";
import { Helmet } from "react-helmet";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const carouselSettings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isLoading, setLoading] = useState(true);
  const [about, setAbout] = useState([]);
  const [industrySection, setIndustrySection] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [partnerSection, setPartnerSection] = useState([]);
  const [partner, setPartner] = useState([]);
  const [news, setNews] = useState([]);
  const [organizerSection, setOrganizerSection] = useState([]);
  const [organizer, setOrganizer] = useState([]);
  const [ctaSection, setCtaSection] = useState([]);

  // =================== Hero Sliders ===================
  const [allSliders, setAllSliders] = useState([]);
  
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          "https://mccoy-usa.com/admin/api/mainpage"
        );
        setAllSliders(data.banner_section)
        // console.log("data.banner_section", data.banner_section)

        setAbout(data.intro_section);
        setIndustrySection(data.industry_section);
        setIndustry(data.industry);
        setPartnerSection(data.partner_section);
        setPartner(data.partner);
        setNews(data.news);
        setOrganizerSection(data.organizer_section);
        setOrganizer(data.organizer);
        setCtaSection(data.cta_section);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Helmet>
        <title>McCoy Warping Equipment | Textile Machines|USA's Trusted Brand </title>
        <meta name="description" Content="Discover McCoy warping equipment and textile machines, crafted for precision and durability. Trust USA’s leading brand for high-performance textile solutions." />
        <meta name="keywords" content="McCoy Warping Equipment" />
      </Helmet>

       <HeroSection allSliders={allSliders}/>
       
      {/* facility-banner */}
      <section className="py-5">
        {about.map((item, index) => {
          return (
            <div className="container-box row py-md-5 " key={index}>
              <div className="col-md-7 pe-md-5 px-0">
                <h2 className="h2 text-dark-blue">
                  {item.intro_section_title}
                </h2>
                <p className="paragraph text-justify pt-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.intro_section_text,
                    }}
                  />
                </p>

                <Link to="/about" className="carousel-content-btn">
                  {item.intro_section_button_text}
                </Link>
              </div>

              <div className="col-md-5 mt-md-0 mt-5 px-0 position-relative">
                <div className="position-relative bg-info">
                  <img
                    src={`https://mccoy-usa.com/admin/assets/front/img/${item.intro_bg}`}
                    className="w-100 img-fluid company-img"
                    alt="McCoy - American textile machinery for efficient fabric production"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </section>

      <section className="py-5 bg-light">
        <div className="container-box pb-3 py-md-5">
          {industrySection.map((item, index) => {
            return (
              <div className="head-section" key={index}>
                <h2 className="text-dark-blue h2">{item.industries_title}</h2>
                <p>{item.industries_subtitle}</p>
              </div>
            );
          })}
          <div className="row">
            {industry.map((item, index) => {
              return (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 mt-4 pt-2"
                  key={index}
                >
                  <div className="industries-gallary shadow">
                    <img
                      src={`https://mccoy-usa.com/admin/assets/front/img/${item.image}`}
                      alt=""
                      className="w-100"
                      loading="lazy"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="py-5 section-3">
        <div className="container-box py-md-5 ">
          {partnerSection.map((item, index) => {
            return (
              <div className="head-section text-white" key={index}>
                <h2 className="h2">{item.partner_title}</h2>
                <p>{item.partner_subtitle}</p>
              </div>
            );
          })}

          <div className="row justify-content-center align-items-center pt-4 mt-5">
            <div className="col-md-10">
              <Slider {...carouselSettings2} className="text-md-center ">
                {partner.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="text-center">
                        <img
                          src={`https://mccoy-usa.com/admin/assets/front/img/partners/${item.image}`}
                          alt=""
                          className="d-inline partner-img rounded mb-5"
                          loading="lazy"
                        />
                      </div>
                      <p className="text-white mb-0 paragraph px-md-3 px-1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.overview,
                          }}
                        />
                        <h5 className="fw-bold mt-4">{item.url}</h5>
                      </p>
                    </div>
                  );
                })}
                {/* <div>
                  <div className="text-center">
                    <img
                      src={partner1}
                      alt=""
                      className="d-inline partner-img rounded mb-5"
                    />
                  </div>
                  <h5 className="fw-bold mt-4 text-white">Automat Controls</h5>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 section-4">
        <div className="container-box py-md-5">
          <div className="head-section">
            <h2 className="text-dark-blue h2">Trade Shows & Events</h2>
            <p>
              We try to organize amazing shows and events every year in
              organization
            </p>
          </div>

          <div className="row">
            {news.slice(0, 3).map((machine, i) => {
              return (
                <div className="col-md-4 mt-4">
                  <Link
                    to={`/event-details/${machine.slug}`}
                    className="text-decoration-none"
                  >
                    <div className="event-card">
                      <img
                        src={`https://mccoy-usa.com/admin/assets/front/img/blogs/${machine.main_image}`}
                        className="w-100 event-img"
                        alt=""
                        loading="lazy"
                      />
                      <div className="d-flex p-2 pb-2">
                        <div>
                          <p className="mb-0 text-primary text-center">
                            {moment(machine.start_date).format("MMMM")}{" "}
                          </p>
                          <h6 className="fw-bold text-dark-blue">{moment(machine.start_date).format("DD")} - {moment(machine.end_date).format("DD")}</h6>
                        </div>
                        <div className="ms-3 ">
                          <h6 className=" mb-0 text-dark-blue text-center">
                            {machine.title}
                          </h6>
                          <div className="text-dark-blue pt-1">
                            – {machine.location}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="bg-dark-blue py-5">
        <div className="container-box py-md-5">
          <div className="row justify-content-around align-items-center">
            {organizerSection.map((item, index) => {
              return (
                <div
                  className="head-section mb-5 text-white"
                  style={{ width: "70%" }}
                  key={index}
                >
                  <h2 className="h2">{item.organizations_title}</h2>
                  <p>{item.organizations_subtitle}</p>
                </div>
              );
            })}

            <div className="col-md-7 border-right pe-xl-5 ps-md-0">
              <div className="row align-items-center pe-md-5">
                {organizer.map((item, i) => {
                  return (
                    <>
                      <div className="col-6 px-0 mb-4 text-start">
                        <img
                          src={`https://mccoy-usa.com/admin/assets/front/img/${item.image}`}
                          className="org-logo"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            {organizerSection.map((item, index) => {
              return (
                <div className="col-md-4 nonprofit-orgs pe-md-0" key={index}>
                  <p className="text-white mt-md-0 mt-5 text-center fw-bold">
                    {item.organizations1}
                  </p>
                  <h3 className="my-4 ">{item.organizations2}</h3>
                  {/* <h3 className='my-4 '>Katies Kidz</h3> */}
                  <h3 className="my-4 ">{item.organizations3}</h3>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="py-5 section-2">
        <div className="container-box py-4 row align-items-center">
          {ctaSection.map((item, index) => {
            return (
              <div className="col-md-7 text-white" key={index}>
                <h2 className="h2">{item.cta_section_text}</h2>
                <p className="paragraph mt-3">{item.cta_section_overview}</p>
                <Link
                  to="/contact"
                  className="carousel-content-btn d-inline-block mt-4"
                >
                  {" "}
                  {item.cta_section_button_text}
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Home;
